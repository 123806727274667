import * as ActionTypes from '../types';
import { Backend } from '../../services/Backend';

/* ------------------------- statics ----------------- */
export const getStatics = () => {
    return async (dispatch) => {
        const url = `/statistics`;
        await Backend.getCategories(url).then((response) => {
            console.log(response.data.data, 'statics')
            dispatch({
                type: ActionTypes.GET_STATICS,
                data: response.data.data,
            });
        });
    };
};