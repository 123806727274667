import * as ActionTypes from '../types';
import { Backend } from '../../services/Backend';
import * as actions from '..';
import { successMessage, errorMessage } from '../../utils';

/* basic data  ==> used for register restaurant && user form */
export const updatePlaceStore = (place_id, basicData) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_UPDATE_PLACE_LOADING,
        });
        const url = `places/${place_id}/update`;
        await Backend.postRequest(url, basicData).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: ActionTypes.END_UPDATE_PLACE_LOADING,
                });
                dispatch({
                    type: ActionTypes.UPDATE_PLACE_STORE,
                });
                successMessage(response.data.message);
            } else {
                dispatch({
                    type: ActionTypes.END_UPDATE_PLACE_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};

/* logo _ description  */
export const setLogoDescription = (place_id, logoDescription) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_LOGO_DESCRIPTION_LOADING,
        });
        const url = `/places/${place_id}/details`;
        await Backend.postRequest(url, logoDescription).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: ActionTypes.END_LOGO_DESCRIPTION_LOADING,
                });
                dispatch({
                    type: ActionTypes.SET_LOGO_DESCRIPTION,
                });
                successMessage(response.data.message);
            } else {
                dispatch({
                    type: ActionTypes.END_LOGO_DESCRIPTION_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};

/* place images & menu images  */
export const setImages = (place_id, placeImages) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_IMAGES_LOADING,
        });
        const url = `/places/${place_id}/images`; // place_id
        await Backend.postRequest(url, placeImages).then((response) => {
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_IMAGES_LOADING,
                });
                dispatch({
                    type: ActionTypes.SET_IMAGES,
                });
                dispatch(actions.showPlaceDetails(place_id));
                successMessage(response.data.message);
            } else {
                dispatch({
                    type: ActionTypes.END_IMAGES_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};
// delete images
export const deleteImg = (place_id, id) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_DELETE_IMG_LOADING,
        });
        await Backend.deleteImage(place_id, id).then((response) => {
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_DELETE_IMG_LOADING,
                });
                console.log('deleted images');
                dispatch({
                    type: ActionTypes.DELETE_IMG,
                });
                dispatch(actions.showPlaceDetails(place_id));
            } else {
                dispatch({
                    type: ActionTypes.END_DELETE_IMG_LOADING,
                });
            }
        });
    };
};

/* branches */
// update branch
export const updateBranch = (branch_id, formData) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_UPDATE_BRANCHE_LOADING,
        });
        const url = `/branch/${branch_id}/update`;
        await Backend.postRequest(url, formData).then((response) => {
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_UPDATE_BRANCHE_LOADING,
                });
                dispatch({
                    type: ActionTypes.UPDATE_BRANCHE,
                });
                successMessage(response.data.message);
            } else {
                dispatch({
                    type: ActionTypes.END_UPDATE_BRANCHE_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};

/* working hours */

// update working hours
export const updateWorkingHours = (branch_id, formData) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_WORKING_HOURS_LOADING,
        });
        const url = `/branch/working-hours/${branch_id}`;
        await Backend.postRequest(url, formData).then((response) => {
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_WORKING_HOURS_LOADING,
                });
                dispatch({
                    type: ActionTypes.UPDATE_WORKING_HOURS,
                });
                successMessage(response.data.message);
            } else {
                dispatch({
                    type: ActionTypes.END_WORKING_HOURS_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};
// update delivery hours
export const updateDeliveryHours = (branch_id, formData) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_DELIVERY_HOURS_LOADING,
        });
        const url = `/branch/delivery-hours/${branch_id}`;
        await Backend.postRequest(url, formData).then((response) => {
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_DELIVERY_HOURS_LOADING,
                });
                dispatch({
                    type: ActionTypes.UPDATE_DELIVERY_HOURS,
                });
                successMessage(response.data.message);
            } else {
                dispatch({
                    type: ActionTypes.END_DELIVERY_HOURS_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};

/* -------------------------- days used in formdata time  --------------- */
// get days
export const getDays = () => {
    return async (dispatch) => {
        const url = '/days';
        await Backend.getPages(url).then((response) => {
            dispatch({
                type: ActionTypes.GET_DAYS,
                days: response.data.data,
            });
        });
    };
};
