import * as ActionTypes from '../../actions/types';
import { updateObject } from '../utility';

const INIT_STATE = {
    loading: false,
    placeReviews: null,
    activeReviews: null,
    blockedReviews: null,
};

/* for get place reviews */
const getPlaceReviews = (state, action) => {
    return updateObject(state, {
        placeReviews: action.data,
    });
};

/* ---------------- active reviews ----------------- */
// get active reviews
const getActiveReviews = (state, action) => {
    return updateObject(state, {
        activeReviews: action.data,
    });
};

const searchActiveReviewsByPlaceName = (state, action) => {
    return updateObject(state, {
        activeReviews: action.data,
    });
};

const searchActiveReviewsByPhone = (state, action) => {
    return updateObject(state, {
        activeReviews: action.data,
    });
};

const filterActiveReviews = (state, action) => {
    return updateObject(state, {
        activeReviews: action.data,
    });
};

/* ---------------- blocked reviews ----------------- */
const getBlockedReviews = (state, action) => {
    return updateObject(state, {
        blockedReviews: action.data,
    });
};

const searchBlockedReviewsByPlaceName = (state, action) => {
    return updateObject(state, {
        blockedReviews: action.data,
    });
};

const searchBlockedReviewsByPhone = (state, action) => {
    return updateObject(state, {
        blockedReviews: action.data,
    });
};

const filterBlockedReviews = (state, action) => {
    return updateObject(state, {
        blockedReviews: action.data,
    });
};
const reviewsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        // delete review
        case ActionTypes.START_DELETE_REVIEWS_LOADING:
            return { ...state, loading: true };
        case ActionTypes.END_DELETE_REVIEWS_LOADING:
            return { ...state, loading: false };

        // place reviews
        case ActionTypes.GET_PLACE_REVIEWS:
            return getPlaceReviews(state, action);
        /* --------------- active reviews ---------------- */
        // get active reviews
        case ActionTypes.GET_Active_REVIEWS:
            return getActiveReviews(state, action);
        // search reviews
        case ActionTypes.SEARCH_ACTIVE_REVIEW_PLACE_NAME:
            return searchActiveReviewsByPlaceName(state, action);
        case ActionTypes.SEARCH_ACTIVE_REVIEW_PHONE:
            return searchActiveReviewsByPhone(state, action);
        // filter  reviews
        case ActionTypes.FILTER_ACTIVE_REVIEWS:
            return filterActiveReviews(state, action);

        /* --------------- bocked reviews ---------------- */
        case ActionTypes.GET_BLOCKED_REVIEWS:
            return getBlockedReviews(state, action);
        // search reviews
        case ActionTypes.SEARCH_BLOCKED_REVIEW_PLACE_NAME:
            return searchBlockedReviewsByPlaceName(state, action);

        case ActionTypes.SEARCH_BLOCKED_REVIEW_PHONE:
            return searchBlockedReviewsByPhone(state, action);
        // filter  reviews
        case ActionTypes.FILTER_BLOCKED_REVIEWS:
            return filterBlockedReviews(state, action);
        default:
            return state;
    }
};
export default reviewsReducer;
