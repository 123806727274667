import React from 'react';
import strings from '../../strings';
import { Form, Input, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { shallowEqual, useSelector } from 'react-redux';
import * as actions from '../../actions';
import { Redirect } from 'react-router-dom';
import login_image from '../../assets/images/login_image.png';
import logo from '../../assets/images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const formItemLayout = {
    labelCol: {
        xs: {
            span: 4,
        },
    },
    wrapperCol: {
        xs: {
            span: 20,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
        },
    },
};

const Login = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { loading, token } = useSelector(
        (state) => ({
            loading: state.Auth.loading,
            token: state.Auth.token,
        }),
        shallowEqual
    );
    const onFinish = (values) => {
        postRequest(values);
    };

    const postRequest = (values) => {
        dispatch(actions.login(values));
    };

    const userType = localStorage.getItem('userType');
    /* redirect user after login */
    if (token !== null && userType === 'admin') {
        return <Redirect to="/dashboards" />;
    }
    return (
        <>
            <div
                className="container-fluid p-0 login-form dashboard-forms"
                style={{ position: 'absolute', top: '0', left: '0', right: '0', bottom: '0' }}
            >
                <div className="row no-gutters" style={{ height: '100%' }}>
                    <div className="d-none d-lg-block col-lg-4">
                        <img src={login_image} alt="login mage" width="100%" height="100%" />
                    </div>
                    <div className="col-lg-8 d-flex justify-content-center align-items-center">
                        <div className="container">
                            <div className="logo-container text-center">
                                <img src={logo} alt="logo" />
                                <p>{strings.login_page.log}</p>
                            </div>

                            <Form
                                {...formItemLayout}
                                grid="true"
                                form={form}
                                name="register"
                                onFinish={onFinish}
                                scrollToFirstError
                            >
                                {/* phone number */}
                                <Form.Item
                                    name="phone"
                                    label={strings.login_page.phone}
                                    rules={[
                                        {
                                            required: true,
                                            message: strings.login_page.error_msgs.phone,
                                        },
                                        {
                                            pattern: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
                                            message: strings.login_page.error_msgs.phone_invalid,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                {/* password */}
                                <Form.Item
                                    name="password"
                                    label={strings.login_page.password}
                                    rules={[
                                        {
                                            required: true,
                                            message: strings.login_page.error_msgs.password,
                                        },
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                                <div className="btn-container">
                                    <Form.Item {...tailFormItemLayout}>
                                        <Button className="px-5 " type="primary" htmlType="submit">
                                            {loading === true && (
                                                <FontAwesomeIcon icon={faSpinner} className="ml-1 spinner" />
                                            )}
                                            {strings.login_page.login}
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Login;
