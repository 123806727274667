import * as ActionTypes from '../types';
import { Backend } from '../../services/Backend';
import * as actions from '../../actions';
import { successMessage, errorMessage } from '../../utils';

/* ------------------------- categories ----------------- */
// get categories
/* 
=> all =1
it will return all categories and sub categories
*/
export const getCategories = () => {
    return async (dispatch) => {
        const url = `/categories/index`;
        const all = 1;
        const is_searchable = 1;
        await Backend.getCategories(url, all, is_searchable).then((response) => {
            dispatch({
                type: ActionTypes.GET_CATEGORIES,
                categories: response.data.data,
            });
        });
    };
};

// get subcategories by id for selectbox
export const getSubCategoriesById = (id) => {
    return async (dispatch) => {
        const url = `/categories/index?id=${id}`;
        await Backend.getCategories(url).then((response) => {
            dispatch({
                type: ActionTypes.GET_SUB_CATEGORIES_BY_ID,
                subCategoriesById: response.data.data,
            });
        });
    };
};

// post categories
export const categoriesStore = (data) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_STORE_CATEGORIES_LOADING,
        });
        const url = '/categories/store';
        await Backend.postRequest(url, data).then((response) => {
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_STORE_CATEGORIES_LOADING,
                });
                dispatch({
                    type: ActionTypes.STORE_CATEGORIES,
                });
                dispatch(actions.getCategories());
                successMessage(response.data.message);
            } else {
                dispatch({
                    type: ActionTypes.END_STORE_CATEGORIES_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};

// update categories
export const categoriesUpdate = (id, data) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_UPDATE_CATEGORIES_LOADING,
        });
        const url = `/categories/${id}/update`;
        await Backend.postRequest(url, data).then((response) => {
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_UPDATE_CATEGORIES_LOADING,
                });
                dispatch({
                    type: ActionTypes.UPDATE_CATEGORIES,
                });
                dispatch(actions.getCategories());
                successMessage(response.data.message);
            } else {
                dispatch({
                    type: ActionTypes.END_UPDATE_CATEGORIES_LOADING,
                });
                errorMessage (response.data.message);
            }
        });
    };
};

// delete categories
export const deleteCategories = (id) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_DELETE_CATEGORIES_LOADING,
        });
        const url = `/categories/delete/${id}`;
        await Backend.deleteRequest(url).then((response) => {
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_DELETE_CATEGORIES_LOADING,
                });
                dispatch({
                    type: ActionTypes.DELETE_CATEGORIES,
                });
                dispatch(actions.getCategories());
                successMessage(response.data.message);
            } else {
                dispatch({
                    type: ActionTypes.END_DELETE_CATEGORIES_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};

/* ------------------------------- services ------------------------- */
// get services
export const getServices = (pageNumber) => {
    return async (dispatch) => {
        const url = 'services/index';
        await Backend.getPages(url, pageNumber).then((response) => {
            dispatch({
                type: ActionTypes.GET_SERVICES,
                services: response.data.data,
            });
        });
    };
};

// post services
export const servicesStore = (data) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_STORE_SERVICES_LOADING,
        });
        const url = '/services/store';
        await Backend.postRequest(url, data).then((response) => {
            console.log('code', response && response.data.code);
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_STORE_SERVICES_LOADING,
                });
                dispatch({
                    type: ActionTypes.STORE_SERVICES,
                });
                const state = JSON.parse(localStorage.getItem('state'));
                dispatch(actions.getServices(state.PlaceDetails.services.current_page));
                successMessage(response.data.message);
            } else {
                dispatch({
                    type: ActionTypes.END_STORE_SERVICES_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};

// update services
export const servicesUpdate = (id, data) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_UPDATE_SERVICES_LOADING,
        });
        const url = `/services/${id}/update`;
        await Backend.postRequest(url, data).then((response) => {
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_UPDATE_SERVICES_LOADING,
                });
                dispatch({
                    type: ActionTypes.UPDATE_SERVICES,
                });
                const state = JSON.parse(localStorage.getItem('state'));
                dispatch(actions.getServices(state.PlaceDetails.services.current_page));
                successMessage(response.data.message);
            } else {
                dispatch({
                    type: ActionTypes.END_UPDATE_SERVICES_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};

// delete services
export const deleteServices = (id) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_DELETE_SERVICES_LOADING,
        });
        const url = `/services/delete/${id}`;
        await Backend.deleteRequest(url).then((response) => {
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_DELETE_SERVICES_LOADING,
                });
                dispatch({
                    type: ActionTypes.DELETE_SERVICES,
                });
                const state = JSON.parse(localStorage.getItem('state'));
                dispatch(actions.getServices(state.PlaceDetails.services.current_page));
                successMessage(response.data.message);
            } else {
                dispatch({
                    type: ActionTypes.END_DELETE_SERVICES_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};
