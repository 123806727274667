import * as ActionTypes from '../types';
import { Backend } from '../../services/Backend';
import * as actions from '../../actions';
import { successMessage, errorMessage } from '../../utils';

/* ---------------------------------- places ----------------------- */
// get places (get request)
export const getPlaces = (pageNumber) => {
    return async (dispatch) => {
        const url = '/places/index';
        await Backend.getPages(url, pageNumber).then((response) => {
            dispatch({
                type: ActionTypes.GET_PLACES,
                data: response.data.data,
            });
        });
    };
};

// details (form) of certain restaurant
// show form inputs of this place
export const showPlaceDetails = (place_id) => {
    return async (dispatch) => {
        const url = `/places/${place_id}/show`;
        await Backend.showPlaceDetails(url).then((response) => {
            console.log('place show respone', response);
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.SHOW_PLACES_DETAILS,
                    data: response.data.data,
                });
            }
        });
    };
};

// search places
export const searchPlaces = (name) => {
    return async (dispatch) => {
        const url = `places/index?name=${name}`;
        await Backend.search(url).then((response) => {
            console.log('serach places', response.data.data);
            dispatch({
                type: ActionTypes.SEARCH_PLACES,
                data: response.data.data,
            });
        });
    };
};

// filter places
export const filterPlaces = (
    rate,
    categories_ids,
    has_offers,
    branch_numbers,
    services
) => {
    return async (dispatch) => {
        let url;
        if (localStorage.getItem('services') && localStorage.getItem('services') !== null) {
            url = `places/index?rate=${rate}&categories_ids=[${categories_ids}]&has_offers=${has_offers}&branch_numbers=${branch_numbers}&services[0]=${services} `;
        } else {
            url = `places/index?rate=${rate}&categories_ids=[${categories_ids}]&has_offers=${has_offers}&branch_numbers=${branch_numbers} `;
        }
        await Backend.search(url).then((response) => {
            dispatch({
                type: ActionTypes.FILTER_PLACES,
                data: response.data.data,
            });
        });
    };
};

/* --------------------------------- blocked places ------------------------------- */
// block place (delete request)
export const blockPlaces = (id) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_BLOCK_LOADING,
        });
        const url = `/places/${id}/block`;
        await Backend.deleteRequest(url).then((response) => {
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_BLOCK_LOADING,
                });
                dispatch({
                    type: ActionTypes.BLOCK_PLACES,
                });
                const state = JSON.parse(localStorage.getItem('state'));
                dispatch(actions.getPlaces(state.PlacesReducer.places.meta.current_page));
                successMessage(response.data.message);
            } else {
                dispatch({
                    type: ActionTypes.END_BLOCK_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};

// block place (get request)
export const getBlockedPlaces = (pageNumber) => {
    return async (dispatch) => {
        const url = 'places/block';
        await Backend.getPages(url, pageNumber).then((response) => {
            dispatch({
                type: ActionTypes.GET_BLOCKED_PLACES,
                data: response.data.data,
            });
        });
    };
};

// unblock places (post request)
export const unblockPlaces = (id) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_UNBLOCK_LOADING,
        });
        const url = `/places/${id}/unblock`;
        await Backend.postRequest(url).then((response) => {
            if (response.status === 200) {
                successMessage(response.data.message);
                dispatch({
                    type: ActionTypes.END_UNBLOCK_LOADING,
                });
                dispatch({
                    type: ActionTypes.UNBLOCK_PLACES,
                });
                const state = JSON.parse(localStorage.getItem('state'));
                console.log(state, 'get state ----------')
                dispatch(
                    actions.getBlockedPlaces(
                        state.PlacesReducer.blockedPlaces.meta.current_page
                    )
                );
            } else {
                dispatch({
                    type: ActionTypes.END_UNBLOCK_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};

// search block places
export const searchBlockPlaces = (name) => {
    return async (dispatch) => {
        const url = `places/block?name=${name}`;
        await Backend.search(url).then((response) => {
            console.log('serach places', response.data.data);
            dispatch({
                type: ActionTypes.SEARCH_BLOCK_PLACES,
                data: response.data.data,
            });
        });
    };
};

// filteration
export const filterBlockedPlaces = (
    rate,
    categories_ids,
    has_offers,
    branch_numbers,
    services
) => {
    return async (dispatch) => {
        let url;
        if (localStorage.getItem('services') && localStorage.getItem('services') !== null) {
            url = `places/block?rate=${rate}&categories_ids=[${categories_ids}]&has_offers=${has_offers}&branch_numbers=${branch_numbers}&services[0]=${services} `;
        } else {
            url = `places/block?rate=${rate}&categories_ids=[${categories_ids}]&has_offers=${has_offers}&branch_numbers=${branch_numbers} `;
        }
        await Backend.search(url).then((response) => {
            dispatch({
                type: ActionTypes.FILTER_BLOCK_PLACES,
                data: response.data.data,
            });
        });
    };
};
