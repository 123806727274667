import { useState } from 'react';
import {
    DropdownToggle,
    DropdownMenu,
    Nav,
    NavItem,
    NavLink,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import { CaretDownOutlined } from '@ant-design/icons';
import strings from '../../strings';
import logoImg from '../../assets/images/logo.png';
import avatar from '../../assets/images/restaurant.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import ConfirmModal from '../shared/Modals/ConfirmModal';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as actions from '../../actions';
const AppHeader = () => {
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();

    const showModal = () => {
        setVisible(true);
    };
    const handleCancel = () => {
        setVisible(false);
    };
    const logoutHandler = () => {
        dispatch(actions.logout());
        handleCancel();
        <Redirect to="/login" />
    }
    return (
        <>
            {/* notification */}
            <div className="d-none d-lg-flex col-lg-4 align-items-center">
                <FontAwesomeIcon
                    icon={faBell}
                    size="lg"
                    className="notification-icon"
                />
            </div>
            {/* logo */}
            <div className="col-4  d-flex justify-content-center">
                <div className="header-logo" style={{ width: '30px' }}>
                    <img
                        src={logoImg}
                        alt="logo_image"
                        style={{ width: '100%' }}
                    />
                </div>
            </div>
            {/* dropdown */}
            <div className="col-4 ">
                <UncontrolledButtonDropdown>
                    <DropdownToggle
                        color="link"
                        className="d-flex align-items-center restaurant-dropdown"
                        style={{ padding: '0' }}
                    >
                        <div className="d-flex justify-content-start align-items-center ml-1">
                            <img
                                width={42}
                                className="rounded-circle"
                                src={avatar}
                                alt="restaurant logo"
                            />
                        </div>
                        <CaretDownOutlined />
                    </DropdownToggle>
                    <DropdownMenu
                        right
                        className="rm-pointers dropdown-menu-lg"
                    >
                        <Nav vertical>
                            <NavItem>
                                <NavLink onClick={showModal}>
                                    {strings.header_user.logout}
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
            </div>

            {/* modal */}
            <ConfirmModal
                visible={visible}
                onCancel={handleCancel}
                onAccept={logoutHandler}
                msg={strings.sidebar.modal.msg}
            />
        </>
    );
};

export default AppHeader;
