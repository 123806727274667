import * as ActionTypes from '../../actions/types';
import { updateObject } from '../utility';

const INIT_STATE = {
    users: null,
};

// get users
const getUsers = (state, action) => {
    return updateObject(state, {
        users: action.data,
    });
};

// for searched users
const searchUsers = (state, action) => {
    return updateObject(state, {
        users: action.data,
    });
};

const UserReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        /* users */
        case ActionTypes.GET_USERS:
            return getUsers(state, action);

        case ActionTypes.SEARCH_USERS:
            return searchUsers(state, action);

        case ActionTypes.START_RESET_PASSWORD_LOADING:
            return { ...state, loading: true };
        case ActionTypes.END_RESET_PASSWORD_LOADING:
            return { ...state, loading: false };
        default:
            return state;
    }
};
export default UserReducer;
