import * as ActionTypes from '../types';
import { Backend } from '../../services/Backend';
import * as axios from 'axios';
import { successMessage, errorMessage } from '../../utils';

export const login = (authData) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_LOGIN_LOADING,
        });
        const url = '/login';
        await Backend.postRequest(url, authData).then((response) => {
            console.log('login', response);
            if (response && response.status === 200) {
                //window.location.reload();
                dispatch({
                    type: ActionTypes.END_LOGIN_LOADING,
                });
                axios.defaults.headers.common.Authorization = `bearer ${response.data.data.token}`;
                localStorage.setItem('token', response.data.data.token);
                localStorage.setItem('userType', response.data.data.user.type);
                dispatch({
                    type: ActionTypes.LOGIN,
                    token: response.data.data.token,
                });
            } else {
                dispatch({
                    type: ActionTypes.END_LOGIN_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};

/* register: {image, name, phone, password} */
export const placeStore = (data) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_REGISTER_LOADING,
        });
        const url = '/places/store';
        await Backend.postRequest(url, data).then((response) => {
            if (response && response.status === 200) {
                dispatch({
                    type: ActionTypes.END_REGISTER_LOADING,
                });
                dispatch({
                    type: ActionTypes.SET_PLACES_STORE,
                });
                successMessage(response.data.message);
            } else {
                dispatch({
                    type: ActionTypes.END_REGISTER_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};

/* logout */
export const logout = () => {
    localStorage.clear();
    return {
        type: ActionTypes.LOGOUT,
    };
};
