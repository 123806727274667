import * as ActionTypes from '../../actions/types';
import { updateObject } from '../utility';
const INIT_STATE = {
    loading: false,
    token: null,
    userId: null,
    userType: null,
};

const authLogin = (state, action) => {
    return updateObject(state, {
        token: action.token,
        userId: action.userId,
        userType: action.userType,
    });
};

/* for log out */
const authLogout = (state) => {
    return updateObject(state, { token: null, userId: null });
};

const Auth = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActionTypes.START_LOGIN_LOADING:
            return { ...state, loading: true };
        case ActionTypes.END_LOGIN_LOADING:
            return { ...state, loading: false };
        case ActionTypes.LOGIN:
            return authLogin(state, action);

        case ActionTypes.LOGOUT:
            return authLogout(state, action);

            case ActionTypes.START_REGISTER_LOADING:
                return { ...state, loading: true };
            case ActionTypes.END_REGISTER_LOADING:
                return { ...state, loading: false };            
        default:
            return state;
    }
};
export default Auth;
