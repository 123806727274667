import * as ActionTypes from '../../actions/types';
import { updateObject } from '../utility';

const INIT_STATE = {
    loading: false,
    days: [],
};

/* get days */
const getDays = (state, action) => {
    return updateObject(state, {
        days: action.days,
    });
};
const AllReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        /* update place */
        case ActionTypes.START_UPDATE_PLACE_LOADING:
            return { ...state, loading: true };
        case ActionTypes.END_UPDATE_PLACE_LOADING:
            return { ...state, loading: false };

        /* logo-description */
        case ActionTypes.START_LOGO_DESCRIPTION_LOADING:
            return { ...state, loading: true };
        case ActionTypes.END_LOGO_DESCRIPTION_LOADING:
            return { ...state, loading: false };

        /* images */
        case ActionTypes.START_IMAGES_LOADING:
            return { ...state, loading: true };
        case ActionTypes.END_IMAGES_LOADING:
            return { ...state, loading: false };

        case ActionTypes.START_DELETE_IMG_LOADING:
            return { ...state, loading: true };
        case ActionTypes.END_DELETE_IMG_LOADING:
            return { ...state, loading: false };

        /* branches */
        case ActionTypes.START_UPDATE_BRANCHE_LOADING:
            return { ...state, loading: true };
        case ActionTypes.END_UPDATE_BRANCHE_LOADING:
            return { ...state, loading: false };

        /* working hours */
        case ActionTypes.START_WORKING_HOURS_LOADING:
            return { ...state, loading: true };
        case ActionTypes.END_WORKING_HOURS_LOADING:
            return { ...state, loading: false };

        /* delivery hours */
        case ActionTypes.START_DELIVERY_HOURS_LOADING:
            return { ...state, loading: true };
        case ActionTypes.END_DELIVERY_HOURS_LOADING:
            return { ...state, loading: false };

        case ActionTypes.GET_DAYS:
            return getDays(state, action);
        default:
            return state;
    }
};
export default AllReducer;
