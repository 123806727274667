import ReduxThunk from 'redux-thunk';
import {createStore, applyMiddleware, compose} from 'redux';
import reducers from '../reducers';

// function for save local storage
function saveToLocalStorage(state){
    try{
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState)
    }
    catch(e){
        console.log(e)
    }
}

// function to load from localStorage
function loadToLocalStorage(state){
    try{
        const serializedState = localStorage.getItem('state')
        if(serializedState === null) return undefined;
        return JSON.parse(serializedState)
    }
    catch(e){
        console.log(e)
        return undefined
    }
}

// create variable to return from localstorage
const persistedState = loadToLocalStorage();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    reducers, 
    persistedState,
    composeEnhancers(applyMiddleware(ReduxThunk)));


/* subscribe to our store so that everytime the store changes we can then save that change to 
the localstore by passing-> store.getState to our functon
*/
store.subscribe(() => saveToLocalStorage(store.getState()))
export default store;

