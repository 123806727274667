/* eslint-disable array-callback-return */
import * as ActionTypes from '../../actions/types';
import { updateObject } from '../utility';

const INIT_STATE = {
    loading: false,
    // offers
    offers: null,
    blockedOffers: null,
    //notifications
    notifications: null,
    addNotificationLoading: false,
    acceptNotificationLoading: false,
    rejectNotificationLoading: false,
    sendNotificationLoading: false,
    deleteNotificationLoading: false,
    status: [],
};

// status
const getStatus = (state, action) => {
    const data = [];
    action.data.map((item) => {
        data.push({
            value: item.id,
            label: item.name,
        });
    });
    return updateObject(state, {
        status: data,
    });
};

/* offers */
// get offers
const getOffers = (state, action) => {
    return updateObject(state, {
        offers: action.data,
    });
};

// get blocked offers
const getBlockedOffers = (state, action) => {
    return updateObject(state, {
        blockedOffers: action.data,
    });
};

/* notifications */
// get notifications
const getNotifications = (state, action) => {
    return updateObject(state, {
        notifications: action.data,
    });
};
const Features = (state = INIT_STATE, action) => {
    switch (action.type) {
        /* ------------- status ------------- */
        case ActionTypes.GET_STATUS:
            return getStatus(state, action);
        /* ------------- offers ------------- */
        // accept offer loading
        case ActionTypes.START_ACCEPT_OFFER_LOADING:
            return { ...state, loading: true };
        case ActionTypes.END_ACCEPT_OFFER_LOADING:
            return { ...state, loading: false };
        // reject offer loading
        case ActionTypes.START_REJECT_OFFER_LOADING:
            return { ...state, loading: true };
        case ActionTypes.END_REJECT_OFFER_LOADING:
            return { ...state, loading: false };

        // get offers
        case ActionTypes.GET_OFFERS:
            return getOffers(state, action);

        // delete offer
        case ActionTypes.START_DELETE_OFFER_LOADING:
            return { ...state, loading: true };
        case ActionTypes.END_DELETE_OFFER_LOADING:
            return { ...state, loading: false };

        // block offer loading
        case ActionTypes.START_BLOCK_OFFER_LOADING:
            return { ...state, loading: true };
        case ActionTypes.END_BLOCK_OFFER_LOADING:
            return { ...state, loading: false };

        // unblock offer loading
        case ActionTypes.START_UNBLOCK_OFFER_LOADING:
            return { ...state, loading: true };
        case ActionTypes.END_UNBLOCK_OFFER_LOADING:
            return { ...state, loading: false };

        // ADD offer loading
        case ActionTypes.START_ADD_OFFER_LOADING:
            return { ...state, loading: true };
        case ActionTypes.END_ADD_OFFER_LOADING:
            return { ...state, loading: false };

        // update offer loading
        case ActionTypes.START_UPDATE_OFFER_LOADING:
            return { ...state, loading: true };
        case ActionTypes.END_UPDATE_OFFER_LOADING:
            return { ...state, loading: false };

        //  get blocked offer
        case ActionTypes.GET_BLOCKED_OFFERS:
            return getBlockedOffers(state, action);
        /* ------------- notifications ------------- */
        case ActionTypes.GET_NOTIFICATIONS:
            return getNotifications(state, action);
        // add notification
        case ActionTypes.START_ADD_NOTIFICATION_LOADING:
            return { ...state, addNotificationLoading: true };
        case ActionTypes.END_ADD_NOTIFICATION_LOADING:
            return { ...state, addNotificationLoading: false };
        // accept notification
        case ActionTypes.START_ACCEPT_NOTIFICATION_LOADING:
            return { ...state, acceptNotificationLoading: true };
        case ActionTypes.END_ACCEPT_NOTIFICATION_LOADING:
            return { ...state, acceptNotificationLoading: false };
        // reject notification
        case ActionTypes.START_REJECT_NOTIFICATION_LOADING:
            return { ...state, rejectNotificationLoading: true };
        case ActionTypes.END_REJECT_NOTIFICATION_LOADING:
            return { ...state, rejectNotificationLoading: false };
        // send notification
        case ActionTypes.START_SEND_NOTIFICATION_LOADING:
            return { ...state, sendNotificationLoading: true };
        case ActionTypes.END_SEND_NOTIFICATION_LOADING:
            return { ...state, sendNotificationLoading: false };
        // delete notification
        case ActionTypes.START_DELETE_NOTIFICATION_LOADING:
            return { ...state, deleteNotificationLoading: true };
        case ActionTypes.END_DELETE_NOTIFICATION_LOADING:
            return { ...state, deleteNotificationLoading: false };
        default:
            return state;
    }
};
export default Features;
