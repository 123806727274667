module.exports = {
    /* -------------------- auth  ------------- */
    START_LOGIN_LOADING: 'START_LOGIN_LOADING',
    END_LOGIN_LOADING: 'END_LOGIN_LOADING',
    LOGIN: 'LOGIN',

    LOGOUT: 'LOGOUT',

    START_REGISTER_LOADING: 'START_Register_LOADING',
    END_REGISTER_LOADING: 'END_Register_LOADING',
    SET_PLACES_STORE: 'SET_PLACES_STORE' /* register place*/,

    /* ------------------ statics ----------------- */
    GET_STATICS: 'GET_STATICS',
    
    /* -------------------- all  --------------------- */
    // places
    GET_PLACES: 'GET_PLACES',
    SHOW_PLACES_DETAILS: 'SHOW_PLACES_DETAILS', // for restaurant details
    SEARCH_PLACES: 'SEARCH_PLACES',
    FILTER_PLACES: 'FILTER_PLACES',

    // users
    GET_USERS: 'GET_USERS',
    SEARCH_USERS: 'SEARCH_USERS',

    // reset password
    START_RESET_PASSWORD_LOADING: 'START_RESET_PASSWORD_LOADING',
    END_RESET_PASSWORD_LOADING: 'END_RESET_PASSWORD_LOADING',
    REST_PASSWORD: 'REST_PASSWORD',

    // messages
    // => clients msgs
    GET_CLIENTS_MSGS: 'GET_CLIENTS_MSGS',
    SEARCH_CLIENTS_MSGS: 'SEARCH_CLIENTS_MSGS',

    // => restaurant msgs
    GET_RestaurantS_MSGS: 'GET_RestaurantS_MSGS',
    SEARCH_RestaurantS_MSGS: 'SEARCH_RestaurantS_MSGS',

    // blocked places
    START_BLOCK_LOADING: 'START_BLOCK_LOADING',
    END_BLOCK_LOADING: 'END_BLOCK_LOADING',
    BLOCK_PLACES: 'BLOCK_PLACES',
    SEARCH_BLOCK_PLACES: 'SEARCH_BLOCK_PLACES',
    FILTER_BLOCK_PLACES: 'FILTER_BLOCK_PLACES',

    START_UNBLOCK_LOADING: 'START_UNBLOCK_LOADING',
    END_UNBLOCK_LOADING: 'END_UNBLOCK_LOADING',
    UNBLOCK_PLACES: 'UNBLOCK_PLACES',

    GET_BLOCKED_PLACES: 'GET_BLOCKED_PLACES',

    // reviews
    GET_Active_REVIEWS: 'GET_Active_REVIEWS',
    SEARCH_ACTIVE_REVIEW_PLACE_NAME: 'SEARCH_ACTIVE_REVIEW_PLACE_NAME',
    SEARCH_ACTIVE_REVIEW_PHONE: 'SEARCH_ACTIVE_REVIEW_PHONE',
    FILTER_ACTIVE_REVIEWS: 'FILTER_ACTIVE_REVIEWS',

    GET_BLOCKED_REVIEWS: 'GET_BLOCKED_REVIEWS',
    SEARCH_BLOCKED_REVIEW_PLACE_NAME: 'SEARCH_BLOCKED_REVIEW_PLACE_NAME',
    SEARCH_BLOCKED_REVIEW_PHONE: 'SEARCH_BLOCKED_REVIEW_PHONE',
    FILTER_BLOCKED_REVIEWS: 'FILTER_BLOCKED_REVIEWS',

    GET_PLACE_REVIEWS: 'GET_PLACE_REVIEWS',

    START_DELETE_REVIEWS_LOADING: 'START_DELETE_REVIEWS_LOADING',
    END_DELETE_REVIEWS_LOADING: 'END_DELETE_REVIEWS_LOADING',
    DELETE_REVIEWS: 'DELETE_REVIEWS',

    /* -------------------- features  --------------------- */
    // status
    GET_STATUS: 'GET_STATUS',
    // offers
    GET_OFFERS: 'GET_OFFERS',

    START_ACCEPT_OFFER_LOADING: 'START_ACCEPT_OFFER_LOADING',
    END_ACCEPT_OFFER_LOADING: 'END_ACCEPT_OFFER_LOADING',
    ACCEPT_OFFER: 'ACCEPT_OFFER',

    START_REJECT_OFFER_LOADING: 'START_REJECT_OFFER_LOADING',
    END_REJECT_OFFER_LOADING: 'END_REJECT_OFFER_LOADING',
    REJECT_OFFER: 'REJECT_OFFER',

    START_DELETE_OFFER_LOADING: 'START_DELETE_OFFER_LOADING',
    END_DELETE_OFFER_LOADING: 'END_DELETE_OFFER_LOADING',
    DELETE_OFFER: 'DELETE_OFFER',

    START_BLOCK_OFFER_LOADING: 'START_BLOCK_OFFER_LOADING',
    END_BLOCK_OFFER_LOADING: 'END_BLOCK_OFFER_LOADING',
    BLOCK_OFFER: 'BLOCK_OFFER',

    START_UNBLOCK_OFFER_LOADING: 'START_UNBLOCK_OFFER_LOADING',
    END_UNBLOCK_OFFER_LOADING: 'END_UNBLOCK_OFFER_LOADING',
    UNBLOCK_OFFER: 'UNBLOCK_OFFER',

    START_ADD_OFFER_LOADING: 'START_ADD_OFFER_LOADING',
    END_ADD_OFFER_LOADING: 'END_ADD_OFFER_LOADING',
    ADD_OFFER: 'ADD_OFFER',

    START_UPDATE_OFFER_LOADING: 'START_UPDATE_OFFER_LOADING',
    END_UPDATE_OFFER_LOADING: 'END_UPDATE_OFFER_LOADING',
    UPDATE_OFFER: 'UPDATE_OFFER',

    GET_BLOCKED_OFFERS: 'GET_BLOCKED_OFFERS',

    FILTER_OFFERS: 'FILTER_OFFERS',

    // notifications
    GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',

    START_ADD_NOTIFICATION_LOADING: 'START_ADD_NOTIFICATION_LOADING',
    END_ADD_NOTIFICATION_LOADING: 'END_ADD_NOTIFICATION_LOADING',
    ADD_NOTIFICATION: 'ADD_NOTIFICATION',

    START_ACCEPT_NOTIFICATION_LOADING: 'START_ACCEPT_NOTIFICATION_LOADING',
    END_ACCEPT_NOTIFICATION_LOADING: 'END_ACCEPT_NOTIFICATION_LOADING',
    ACCEPT_NOTIFICATION: 'ACCEPT_NOTIFICATION',

    START_REJECT_NOTIFICATION_LOADING: 'START_REJECT_NOTIFICATION_LOADING',
    END_REJECT_NOTIFICATION_LOADING: 'END_REJECT_NOTIFICATION_LOADING',
    REJECT_NOTIFICATION: 'REJECT_NOTIFICATION',

    START_SEND_NOTIFICATION_LOADING: 'START_SEND_NOTIFICATION_LOADING',
    END_SEND_NOTIFICATION_LOADING: 'END_SEND_NOTIFICATION_LOADING',
    SEND_NOTIFICATION: 'SEND_NOTIFICATION',

    START_DELETE_NOTIFICATION_LOADING: 'START_DELETE_NOTIFICATION_LOADING',
    END_DELETE_NOTIFICATION_LOADING: 'END_DELETE_NOTIFICATION_LOADING',
    DELETE_NOTIFICATION: 'DELETE_NOTIFICATION',

    /* -------------------- place details  --------------------- */
    GET_CATEGORIES: 'GET_CATEGORIES',
    GET_SUB_CATEGORIES_BY_ID: 'GET_SUB_CATEGORIES_BY_ID',

    START_STORE_CATEGORIES_LOADING: 'START_STORE_CATEGORIES_LOADING',
    END_STORE_CATEGORIES_LOADING: 'END_STORE_CATEGORIES_LOADING',
    STORE_CATEGORIES: 'STORE_CATEGORIES',

    START_UPDATE_CATEGORIES_LOADING: 'START_UPDATE_CATEGORIES_LOADING',
    END_UPDATE_CATEGORIES_LOADING: 'END_UPDATE_CATEGORIES_LOADING',
    UPDATE_CATEGORIES: 'UPDATE_CATEGORIES',

    START_DELETE_CATEGORIES_LOADING: 'START_DELETE_CATEGORIES_LOADING',
    END_DELETE_CATEGORIES_LOADING: 'END_DELETE_CATEGORIES_LOADING',
    DELETE_CATEGORIES: 'DELETE_CATEGORIES',

    GET_SERVICES: 'GET_SERVICES',

    START_STORE_SERVICES_LOADING: 'START_STORE_SERVICES_LOADING',
    END_STORE_SERVICES_LOADING: 'END_STORE_SERVICES_LOADING',
    STORE_SERVICES: 'STORE_SERVICES',

    START_UPDATE_SERVICES_LOADING: 'START_UPDATE_SERVICES_LOADING',
    END_UPDATE_SERVICES_LOADING: 'END_UPDATE_SERVICES_LOADING',
    UPDATE_SERVICES: 'UPDATE_SERVICES',

    START_DELETE_SERVICES_LOADING: 'START_DELETE_SERVICES_LOADING',
    END_DELETE_SERVICES_LOADING: 'END_DELETE_SERVICES_LOADING',
    DELETE_SERVICES: 'DELETE_SERVICES',

    /* -------------------- inner pages  --------------------- */
    /* tabbed form  */
    START_UPDATE_PLACE_LOADING: 'START_UPDATE_PLACE_LOADING',
    END_UPDATE_PLACE_LOADING: 'END_UPDATE_PLACE_LOADING',
    UPDATE_PLACE_STORE: 'UPDATE_PLACE_STORE',

    START_LOGO_DESCRIPTION_LOADING: 'START_LOGO_DESCRIPTION_LOADING',
    END_LOGO_DESCRIPTION_LOADING: 'END_LOGO_DESCRIPTION_LOADING',
    SET_LOGO_DESCRIPTION: 'SET_LOGO_DESCRIPTION',

    START_IMAGES_LOADING: 'START_IMAGES_LOADING',
    END_IMAGES_LOADING: 'END_IMAGES_LOADING',
    SET_IMAGES: 'SET_IMAGES',

    START_DELETE_IMG_LOADING: 'START_DELETE_IMG_LOADING',
    END_DELETE_IMG_LOADING: 'END_DELETE_IMG_LOADING',
    DELETE_IMG: 'DELETE_IMG',

    GET_DAYS: 'GET_DAYS',

    START_UPDATE_BRANCHE_LOADING: 'START_UPDATE_BRANCHE_LOADING',
    END_UPDATE_BRANCHE_LOADING: 'END_UPDATE_BRANCHE_LOADING',
    UPDATE_BRANCHE: 'UPDATE_BRANCHE',

    START_WORKING_HOURS_LOADING: 'START_WORKING_HOURS_LOADING',
    END_WORKING_HOURS_LOADING: 'END_WORKING_HOURS_LOADING',
    UPDATE_WORKING_HOURS: 'UPDATE_WORKING_HOURS',

    START_DELIVERY_HOURS_LOADING: 'START_DELIVERY_HOURS_LOADING',
    END_DELIVERY_HOURS_LOADING: 'END_DELIVERY_HOURS_LOADING',
    UPDATE_DELIVERY_HOURS: 'UPDATE_DELIVERY_HOURS',
};
