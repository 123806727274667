import React from 'react';
import ReactDOM from 'react-dom';
import RouterApp from './routes';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
import './styles/base.scss';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import { ConfigProvider } from 'antd';
// arabic language only for the first version
import strings from './strings';
strings.setLanguage('ar');

ReactDOM.render(
        <Provider store={store}>
            <ConfigProvider direction="rtl">
                <BrowserRouter>
                    <RouterApp />
                </BrowserRouter>
            </ConfigProvider>
        </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
