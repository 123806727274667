import * as ActionTypes from '../types';
import { Backend } from '../../services/Backend';

/* ---------------------- restaurant msgs ----------------- */
// get restaurant msgs (get request)
export const getRestaurantMsgs = (pageNumber) => {
    return async (dispatch) => {
        const url = "contact-us/index";
        const is_client = 0; 
        await Backend.getMessages(url, is_client, pageNumber).then((response) => { //is_client = 0 
            dispatch({
                type: ActionTypes.GET_RestaurantS_MSGS,
                data: response.data.data,
            });
        });
    };
};

// search by name 
export const searchRestaurantMsg = (name) => {
    return async (dispatch) => {
        const url = `contact-us/index?is_client=0&name=${name}`;
        await Backend.search(url).then((response) => { 
            dispatch({
                type: ActionTypes.SEARCH_RestaurantS_MSGS,
                data: response.data.data,
            });
        });
    };
};


/* --------------------- client msgs ---------------------- */
// get client msgs 
export const getClientsMsgs = (pageNumber) => {
    return async (dispatch) => {
        const url = "contact-us/index";
        const is_client = 1; 
        await Backend.getMessages(url, is_client, pageNumber).then((response) => { //is_client = 1
            dispatch({
                type: ActionTypes.GET_CLIENTS_MSGS,
                data: response.data.data,
            });
        });
    };
};

// search by name 
export const searchClientMsg = (name) => {
    return async (dispatch) => {
        const url = `contact-us/index?is_client=1&name=${name}`;
        await Backend.search(url).then((response) => { 
            dispatch({
                type: ActionTypes.SEARCH_CLIENTS_MSGS,
                data: response.data.data,
            });
        });
    };
};
