import * as ActionTypes from '../types';
import { Backend } from '../../services/Backend';
import * as actions from '..';
import { successMessage, errorMessage } from '../../utils';

/* -------------------------------- place review ---------------------- */
// get place reviews
export const getPlaceReviews = (placeId, pageNumber) => {
    return async (dispatch) => {
        const url = '/reviews';
        await Backend.getReviews(url, placeId, pageNumber).then((response) => {
            dispatch({
                type: ActionTypes.GET_PLACE_REVIEWS,
                data: response.data.data,
            });
        });
    };
};

//delete review for each restaurant
export const deletePlaceReview = (place_id, id) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_DELETE_REVIEWS_LOADING,
        });
        const url = `/reviews/${id}`;
        await Backend.deleteRequest(url).then((response) => {
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_DELETE_REVIEWS_LOADING,
                });
                successMessage(response.data.message);
                dispatch({
                    type: ActionTypes.DELETE_REVIEWS,
                });
                const state = JSON.parse(localStorage.getItem('state'));
                dispatch(
                    actions.getPlaceReviews(
                        place_id,
                        state.Reviews.placeReviews.meta.current_page
                    )
                );
            } else {
                dispatch({
                    type: ActionTypes.END_DELETE_REVIEWS_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};

/* ------------------- active reviews -------------------- */
// get active reviews
export const getActiveReviews = (pageNumber) => {
    return async (dispatch) => {
        const url = '/reviews';
        await Backend.getAllReviews(url, 0, pageNumber).then((response) => {
            dispatch({
                type: ActionTypes.GET_Active_REVIEWS,
                data: response.data.data,
            });
        });
    };
};
// search reviews
// by place name
export const searchActiveReviewsByPlaceName = (place_name) => {
    return async (dispatch) => {
        const url = `reviews?place_name=${place_name}`;
        await Backend.getAllReviews(url, 0).then((response) => {
            console.log('serach reviews', response.data.data);
            dispatch({
                type: ActionTypes.SEARCH_ACTIVE_REVIEW_PLACE_NAME,
                data: response.data.data,
            });
        });
    };
};

// by phone number
export const searchActiveReviewsByPhone = (phone) => {
    return async (dispatch) => {
        const url = `reviews?phone=${phone}`;
        await Backend.getAllReviews(url, 0).then((response) => {
            console.log('phone', response.data.data);
            dispatch({
                type: ActionTypes.SEARCH_ACTIVE_REVIEW_PHONE,
                data: response.data.data,
            });
        });
    };
};

// filter reviews
export const filterActiveReviews = (is_replied, rate) => {
    return async (dispatch) => {
        let url;
        if (is_replied && !rate) {
            url = `reviews?is_replied=${is_replied}`;
        } else if (!is_replied && rate) {
            url = `reviews?rate=${rate} `;
        } else if (is_replied && rate) {
            url = `reviews?is_replied=${is_replied}&rate=${rate}  `;
        } else {
            url = `reviews`;
        }

        await Backend.getAllReviews(url, 0).then((response) => {
            dispatch({
                type: ActionTypes.FILTER_ACTIVE_REVIEWS,
                data: response.data.data,
            });
        });
    };
};

/* ------------------- blocked reviews -------------------- */
// get blocked reviews
export const getBlockedReviews = (pageNumber) => {
    return async (dispatch) => {
        const url = '/reviews';
        await Backend.getAllReviews(url, 1, pageNumber).then((response) => {
            dispatch({
                type: ActionTypes.GET_BLOCKED_REVIEWS,
                data: response.data.data,
            });
        });
    };
};

// search reviews
// by place name
export const searchBlockedReviewsByPlaceName = (place_name) => {
    return async (dispatch) => {
        const url = `/reviews?blocked=1&place_name=${place_name}`;
        await Backend.search(url).then((response) => {
            dispatch({
                type: ActionTypes.SEARCH_BLOCKED_REVIEW_PLACE_NAME,
                data: response.data.data,
            });
        });
    };
};

// by phone number
export const searchBlockedReviewsByPhone = (phone) => {
    return async (dispatch) => {
        const url = `reviews?blocked=1&phone=${phone}`;
        await Backend.search(url).then((response) => {
            console.log('phone', response.data.data);
            dispatch({
                type: ActionTypes.SEARCH_BLOCKED_REVIEW_PHONE,
                data: response.data.data,
            });
        });
    };
};

// filter reviews
export const filterBlockedReviews = (is_replied, rate) => {
    return async (dispatch) => {
        let url;
        if (is_replied && !rate) {
            url = `reviews?is_replied=${is_replied}`;
        } else if (!is_replied && rate) {
            url = `reviews?rate=${rate} `;
        } else if (is_replied && rate) {
            url = `reviews?is_replied=${is_replied}&rate=${rate}  `;
        } else {
            url = `reviews`;
        }

        await Backend.getAllReviews(url, 1).then((response) => {
            dispatch({
                type: ActionTypes.FILTER_BLOCKED_REVIEWS,
                data: response.data.data,
            });
        });
    };
};

// delete review
export const deleteReviews = (id, review_state) => {
    console.log(review_state, 'review_state');
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_DELETE_REVIEWS_LOADING,
        });
        const url = `/reviews/${id}`;
        await Backend.deleteRequest(url).then((response) => {
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_DELETE_REVIEWS_LOADING,
                });
                successMessage(response.data.message);
                dispatch({
                    type: ActionTypes.DELETE_REVIEWS,
                });
                const state = JSON.parse(localStorage.getItem('state'));
                if (review_state === 'active') {
                    dispatch(
                        actions.getActiveReviews(
                            state.Reviews.activeReviews.meta.current_page
                        )
                    );
                } else if (review_state === 'blocked') {
                    dispatch(
                        actions.getBlockedReviews(
                            state.Reviews.blockedReviews.meta.current_page
                        )
                    );
                }
                // dispatch(
                //     actions.getActiveReviews(
                //         state.Reviews.AllReviews.meta.current_page
                //     )
                // );
            } else {
                dispatch({
                    type: ActionTypes.END_DELETE_REVIEWS_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};
