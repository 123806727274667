import * as ActionTypes from '../../actions/types';
import { updateObject } from '../utility';

const INIT_STATE = {
    users_restaurants: null,
    users_clients: null,
};

/*  --------------- restaurant messages ----------------*/

//get restaurant messages
const getRestaurantMsgs = (state, action) => {
    return updateObject(state, {
        users_restaurants: action.data,
    });
};

// search restaurant msgs
const searchedRestaurantMsgs = (state, action) => {
    return updateObject(state, {
        users_restaurants: action.data,
    });
};

/* clients messages */

// get clients messages
const getClientsMsgs = (state, action) => {
    return updateObject(state, {
        users_clients: action.data,
    });
};

// search client messages
const searchedClientsMsgs = (state, action) => {
    return updateObject(state, {
        users_clients: action.data,
    });
};

const Messages = (state = INIT_STATE, action) => {
    switch (action.type) {
        /*  --------------- restaurant messages ----------------*/
        // get restaurant messages
        case ActionTypes.GET_RestaurantS_MSGS:
            return getRestaurantMsgs(state, action);
        // search restaurant msgs
        case ActionTypes.SEARCH_RestaurantS_MSGS:
            return searchedRestaurantMsgs(state, action);

        /* ---------------- clients messages ----------------*/
        //get restaurant messages
        case ActionTypes.GET_CLIENTS_MSGS:
            return getClientsMsgs(state, action);
        // search client messages
        case ActionTypes.SEARCH_CLIENTS_MSGS:
            return searchedClientsMsgs(state, action);
        default:
            return state;
    }
};
export default Messages;
