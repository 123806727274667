export const BASE_URL = 'https://fivestars-api.trueapps.co/api';

/*
url
====
https://fivestars.trueapps.co/api

production: 
'https://fivestars-api.trueapps.co/api'
admin access production
===============
+201000000000
123456

-------------------------
local
=====
+2010000000
123456
*/