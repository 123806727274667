import * as ActionTypes from '../types';
import { Backend } from '../../services/Backend';
import * as actions from '../../actions';
import { successMessage, errorMessage } from '../../utils';

/* --------------------------- status -----------------------*/
// get status
export const getStatus = (pageNumber) => {
    return async (dispatch) => {
        const url = '/statuses';
        await Backend.getPages(url, pageNumber).then((response) => {
            console.log('statuses', response.data.data);
            dispatch({
                type: ActionTypes.GET_STATUS,
                data: response.data.data,
            });
        });
    };
};

/* --------------------------- offers -----------------------*/
// get offers
export const getOffers = (status, pageNumber) => {
    return async (dispatch) => {
        const url = `/offers?status=${status}`;
        await Backend.getPages(url, pageNumber).then((response) => {
            console.log('offers', response.data.data);
            dispatch({
                type: ActionTypes.GET_OFFERS,
                data: response.data.data,
            });
        });
    };
};

// accept offer
export const acceptOffer = (offer_id) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_ACCEPT_OFFER_LOADING,
        });
        const url = `/offers/${offer_id}/accept`;
        await Backend.postRequest(url).then((response) => {
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_ACCEPT_OFFER_LOADING,
                });
                dispatch({
                    type: ActionTypes.ACCEPT_OFFER,
                });
                const state = JSON.parse(localStorage.getItem('state'));
                dispatch(actions.getOffers(2, state.Features.offers.meta.current_page));
                successMessage(response.data.message);
            } else {
                dispatch({
                    type: ActionTypes.END_ACCEPT_OFFER_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};

// reject offer
export const rejectOffer = (offer_id) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_REJECT_OFFER_LOADING,
        });
        const url = `/offers/${offer_id}/reject`;
        await Backend.postRequest(url).then((response) => {
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_REJECT_OFFER_LOADING,
                });
                dispatch({
                    type: ActionTypes.REJECT_OFFER,
                });
                const state = JSON.parse(localStorage.getItem('state'));
                dispatch(actions.getOffers(3, state.Features.offers.meta.current_page));
                successMessage(response.data.message);
            } else {
                dispatch({
                    type: ActionTypes.END_REJECT_OFFER_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};

// delete offer
export const deleteOffer = (id) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_DELETE_OFFER_LOADING,
        });
        const url = `/offers/${id}`;
        await Backend.deleteRequest(url).then((response) => {
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_DELETE_OFFER_LOADING,
                });
                dispatch({
                    type: ActionTypes.DELETE_OFFER,
                });
                const state = JSON.parse(localStorage.getItem('state'));
                console.log(state.Features.offers.meta.current_page, 'jiiiiiiiiiiiiiiiiiiii')
                //dispatch(actions.getOffers(state.Features.offers.meta.current_page));
                successMessage(response.data.message);
            } else {
                dispatch({
                    type: ActionTypes.END_DELETE_OFFER_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};

// get blocked offers
export const getBlockedOffers = (pageNumber) => {
    return async (dispatch) => {
        const url = '/offers/block';
        await Backend.getPages(url, pageNumber).then((response) => {
            console.log('offers block -----', response.data.data);
            dispatch({
                type: ActionTypes.GET_BLOCKED_OFFERS,
                data: response.data.data,
            });
        });
    };
};

// block offer
export const blockOffer = (id) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_BLOCK_OFFER_LOADING,
        });
        const url = `/offers/${id}/block`;
        await Backend.deleteRequest(url).then((response) => {
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_BLOCK_OFFER_LOADING,
                });
                dispatch({
                    type: ActionTypes.BLOCK_OFFER,
                });
                const state = JSON.parse(localStorage.getItem('state'));
                dispatch(actions.getOffers(state.Features.offers.data[0].status_id, state.Features.offers.meta.current_page));
                successMessage(response.data.message);
            } else {
                dispatch({
                    type: ActionTypes.END_BLOCK_OFFER_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};

// unblock offers
export const unblockOffer = (id) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_UNBLOCK_OFFER_LOADING,
        });
        const url = `/offers/${id}/unblock`;
        await Backend.postRequest(url).then((response) => {
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_UNBLOCK_OFFER_LOADING,
                });
                dispatch({
                    type: ActionTypes.UNBLOCK_OFFER,
                });
                const state = JSON.parse(localStorage.getItem('state'));
                dispatch(actions.getBlockedOffers(state.Features.blockedOffers.meta.current_page));
                dispatch(actions.getBlockedOffers());
                successMessage(response.data.message);
            } else {
                dispatch({
                    type: ActionTypes.END_UNBLOCK_OFFER_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};

// add offer
export const addOffer = (basicData) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_ADD_OFFER_LOADING,
        });
        const url = `offers/`;
        await Backend.postRequest(url, basicData).then((response) => {
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_ADD_OFFER_LOADING,
                });
                dispatch({
                    type: ActionTypes.ADD_OFFER,
                });
                successMessage(response.data.message);
            } else {
                dispatch({
                    type: ActionTypes.END_ADD_OFFER_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};
// update offer
export const updateOffer = (id, basicData) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_UPDATE_OFFER_LOADING,
        });
        const url = `offers/${id}/update`;
        await Backend.postRequest(url, basicData).then((response) => {
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_UPDATE_OFFER_LOADING,
                });
                dispatch({
                    type: ActionTypes.UPDATE_OFFER,
                });
                successMessage(response.data.message);
            } else {
                dispatch({
                    type: ActionTypes.END_UPDATE_OFFER_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};
// filter offers by statues
export const filterPlaces = (offers_status) => {
    localStorage.setItem('offers_status', offers_status);
    return async (dispatch) => {
        const url = `places/index?status=${offers_status}`;
        await Backend.search(url).then((response) => {
            dispatch({
                type: ActionTypes.FILTER_OFFERS,
                data: response.data.data,
            });
        });
    };
};
/* --------------------------- notifications -----------------------*/
// get notifications
export const getNotifications = (pageNumber) => {
    return async (dispatch) => {
        const url = '/notifications';
        await Backend.getPages(url, pageNumber).then((response) => {
            console.log('notifications', response.data.data);
            dispatch({
                type: ActionTypes.GET_NOTIFICATIONS,
                data: response.data.data,
            });
        });
    };
};
// add notification
export const addNotification = (basicData) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_ADD_NOTIFICATION_LOADING,
        });
        const url = `/notifications`;
        await Backend.postRequest(url, basicData).then((response) => {
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_ADD_NOTIFICATION_LOADING,
                });
                dispatch({
                    type: ActionTypes.ADD_NOTIFICATION,
                });
                successMessage(response.data.message);
                dispatch(actions.getNotifications());
            } else {
                dispatch({
                    type: ActionTypes.END_ADD_NOTIFICATION_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};
// accept notification
export const acceptNotification = (notification_id) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_ACCEPT_NOTIFICATION_LOADING,
        });
        const url = `notifications/${notification_id}/accept`;
        await Backend.postRequest(url).then((response) => {
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_ACCEPT_NOTIFICATION_LOADING,
                });
                dispatch({
                    type: ActionTypes.ACCEPT_NOTIFICATION,
                });
                successMessage(response.data.message);
                const state = JSON.parse(localStorage.getItem('state'));
                dispatch(actions.getNotifications(state.Features.notifications.meta.current_page));
            } else {
                dispatch({
                    type: ActionTypes.END_ACCEPT_NOTIFICATION_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};
// reject notification
export const rejectNotification = (notification_id) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_REJECT_NOTIFICATION_LOADING,
        });
        const url = `/notifications/${notification_id}/reject`;
        await Backend.postRequest(url).then((response) => {
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_REJECT_NOTIFICATION_LOADING,
                });
                dispatch({
                    type: ActionTypes.REJECT_NOTIFICATION,
                });
                successMessage(response.data.message);
                const state = JSON.parse(localStorage.getItem('state'));
                dispatch(actions.getNotifications(state.Features.notifications.meta.current_page));
            } else {
                dispatch({
                    type: ActionTypes.END_REJECT_NOTIFICATION_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};
// send notification
export const sendNotification = (notification_id) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_SEND_NOTIFICATION_LOADING,
        });
        const url = `/notifications/${notification_id}/send`;
        await Backend.postRequest(url).then((response) => {
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_SEND_NOTIFICATION_LOADING,
                });
                dispatch({
                    type: ActionTypes.SEND_NOTIFICATION,
                });
                successMessage(response.data.message);
                const state = JSON.parse(localStorage.getItem('state'));
                dispatch(actions.getNotifications(state.Features.notifications.meta.current_page));
            } else {
                dispatch({
                    type: ActionTypes.END_SEND_NOTIFICATION_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};
// delete notification
export const deleteNotification = (notification_id) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_DELETE_NOTIFICATION_LOADING,
        });
        const url = `/notifications/${notification_id}/delete`;
        await Backend.deleteRequest(url).then((response) => {
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_DELETE_NOTIFICATION_LOADING,
                });
                dispatch({
                    type: ActionTypes.DELETE_NOTIFICATION,
                });
                successMessage(response.data.message);
                const state = JSON.parse(localStorage.getItem('state'));
                dispatch(actions.getNotifications(state.Features.notifications.meta.current_page));
            } else {
                dispatch({
                    type: ActionTypes.END_DELETE_NOTIFICATION_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};
