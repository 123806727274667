import * as ActionTypes from '../../actions/types';
import { updateObject } from '../utility';

const INIT_STATE = {
    // places 
    loading: false,
    places: null,
    details: null,
    // blocked places 
    blockedLoading: false,
    blockedPlaces: null,
};

/* ----------------------- places ------------------------------*/
// get places
const getPlaces = (state, action) => {
    return updateObject(state, {
        places: action.data,
    });
};

// show place details
const showPlaceDetails = (state, action) => {
    return updateObject(state, {
        details: action.data,
    });
};

// search places
const searchPlaces = (state, action) => {
    return updateObject(state, {
        places: action.data,
    });
};

// filter places
const filterPlaces = (state, action) => {
    return updateObject(state, {
        places: action.data,
    });
};

/* ---------------------- blocked places ----------------------- */    

/* for get blocked places */
const getBlockedPlaces = (state, action) => {
    return updateObject(state, {
        blockedPlaces: action.data,
    });
};

// search places
const searchBlockPlaces = (state, action) => {
    return updateObject(state, {
        blockedPlaces: action.data,
    });
};
/* filter block places */
const filterBlockPlaces = (state, action) => {
    return updateObject(state, {
        blockedPlaces: action.data,
    });
};

const PlacesReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        /* ----------------------- places ------------------------------*/
        case ActionTypes.GET_PLACES:
            return getPlaces(state, action);

        case ActionTypes.SHOW_PLACES_DETAILS:
            return showPlaceDetails(state, action);

        case ActionTypes.SEARCH_PLACES:
            return searchPlaces(state, action);

        case ActionTypes.FILTER_PLACES:
            return filterPlaces(state, action);

        /* ---------------------- blocked places ----------------------- */    
        /* block loading */
        case ActionTypes.START_BLOCK_LOADING:
            return { ...state, blockedLoading: true };
        case ActionTypes.END_BLOCK_LOADING:
            return { ...state, blockedLoading: false };

        /* unblock loading */
        case ActionTypes.START_UNBLOCK_LOADING:
            return { ...state, blockedLoading: true };
        case ActionTypes.END_UNBLOCK_LOADING:
            return { ...state, blockedLoading: false };

        /* get block places*/
        case ActionTypes.GET_BLOCKED_PLACES:
            return getBlockedPlaces(state, action);

        /* search block places */
        case ActionTypes.SEARCH_BLOCK_PLACES:
            return searchBlockPlaces(state, action);

        /* filter block places */
        case ActionTypes.FILTER_BLOCK_PLACES:
            return filterBlockPlaces(state, action);
        default:
            return state;
    }
    
};
export default PlacesReducer;
