import React, { Suspense, lazy, useState } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Link,
    Redirect,
} from 'react-router-dom';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import strings from '../strings';
import AppHeader from '../components/Layout/AppHeader';
import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import * as actions from '../actions';

import dashboard from '../assets/images/dashboard.png';
import register from '../assets/images/register.png';
import all from '../assets/images/all.png';
import review from '../assets/images/review.png';
import msgs from '../assets/images/msgs.png';
import add from '../assets/images/add.png';
import offers from '../assets/images/offers.png';
import advertisment from '../assets/images/advertisement.png';
import notifications from '../assets/images/notification.png';
import logout from '../assets/images/logout.png';
import { Layout, Menu} from 'antd';
import Loader from '../components/shared/Loader';
import { Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
/* auth components */
import Login from '../pages/Auth/Login';
import Backdrop from '../components/shared/Backdrop';
import ConfirmModal from '../components/shared/Modals/ConfirmModal';

const Analytics = lazy(() => import('../pages/Analytics'));
const Register = lazy(() => import('../pages/Auth/Register'));

// all
const Places = lazy(() => import('../pages/Places/index'));
const Users = lazy(() => import('../pages/Users'));
const ResetPassword = lazy(() => import('../pages/Users/ResetPassword'));
const Messages = lazy(() => import('../pages/Messages'));
const AllReviews = lazy(() => import('../pages/Reviews'));
const PlaceReviews = lazy(() => import('../pages/Reviews/PlaceReviews'));

/* -------------- features --------------------*/
// offers
const Offers = lazy(() => import('../pages/Features/Offers'));
const OfferDetails = lazy(() =>
    import('../pages/Features/Offers/OfferDetails')
);
const AddOffer = lazy(() => import('../pages/Features/Offers/AddOffer'));

// advertisments
const Advertisments = lazy(() => import('../pages/Features/Advertisments'));
const AdvertismentsDetails = lazy(() =>
    import('../pages/Features/Advertisments/AdvertismentDetails')
);

// notifications
const Notifications = lazy(() => import('../pages/Features/Notifications'));

/* -------------- place details --------------------*/
const CategoriesServices = lazy(() => import('../pages/PlaceDetails'));

// place data form
const PlaceDataForm = lazy(() => import('../pages/PlaceDataForm'));

const { Header, Content, Sider } = Layout;

const RouterApp = () => {
    const [mobileSidebar, setMobileSidebar] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [visible, setVisible] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    const { details, token } = useSelector(
        (state) => ({
            details: state.PlacesReducer.details,
            token: state.Auth.token,
        }),
        shallowEqual
    );
    /* for collapse */
    const toggle = () => {
        setCollapsed(!collapsed);
    };

    /* for modal */
    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = () => {
        setVisible(false);
    };
    /* for mobile sidebar */
    const mobileSidebarHandler = () => {
        setMobileSidebar(!mobileSidebar);
    };

    const logoutHandler = () => {
        dispatch(actions.logout());
        handleCancel();
        <Redirect to="/login" />
    }

    return (
        <Router>
            <Layout style={{ minHeight: '100vh' }}>
                <Backdrop show={mobileSidebar} closed={mobileSidebarHandler} />
                <Sider
                    trigger={null}
                    collapsible
                    collapsed={collapsed}
                    className={
                        mobileSidebar === false
                            ? 'show-mobile-sidebar'
                            : 'close-mobile-sidebar'
                    }
                    onClick={mobileSidebarHandler}
                >
                    {React.createElement(
                        collapsed ? MenuOutlined : CloseOutlined,
                        {
                            className: 'trigger d-none d-lg-block',
                            onClick: toggle,
                        }
                    )}

                    <Menu
                        theme="dark"
                        mode="inline"
                        defaultSelectedKeys={[location.pathname]}
                    >
                        {/* dashboard */}
                        <Menu.Item
                            key="/dashboards"
                            icon={
                                <img
                                    src={dashboard}
                                    alt="dashboard"
                                    style={{ width: '20px' }}
                                />
                            }
                        >
                            {strings.sidebar.dashboard}
                            <Link to="/dashboards" />
                        </Menu.Item>
                        {/* notifications */}
                        <Menu.Item
                            key="/notifications"
                            icon={
                                <img
                                    src={notifications}
                                    alt="notifications"
                                    style={{ width: '20px' }}
                                />
                            }
                        >
                            {strings.sidebar.notifications}
                            <Badge className="rounded-pill mr-3">25</Badge>
                            <Link to="/notifications" />
                        </Menu.Item>
                        {/* register */}
                        <Menu.Item
                            key="/register"
                            icon={
                                <img
                                    src={register}
                                    alt="register"
                                    style={{ width: '20px' }}
                                />
                            }
                        >
                            {strings.sidebar.register}
                            <Link to="/register" />
                        </Menu.Item>
                        {/* places  */}
                        <Menu.Item
                            key="/places"
                            icon={
                                <img
                                    src={all}
                                    alt="all restaurants"
                                    style={{ width: '20px' }}
                                />
                            }
                        >
                            {strings.sidebar.all}
                            <Link to="/places" />
                        </Menu.Item>
                        {/* users */}
                        <Menu.Item
                            key="/users"
                            icon={
                                <FontAwesomeIcon
                                    icon={faUsers}
                                    style={{
                                        fontSize: '20px',
                                        color: '#CFCCD3',
                                    }}
                                />
                            }
                        >
                            {strings.sidebar.users}
                            <Link to="/users" />
                        </Menu.Item>
                        {/* reviews */}
                        <Menu.Item
                            key="/all-reviews"
                            icon={
                                <img
                                    src={review}
                                    alt="reviews"
                                    style={{ width: '20px' }}
                                />
                            }
                        >
                            {strings.sidebar.reviews}
                            <Link to="/all-reviews" />
                        </Menu.Item>
                        {/* messages */}
                        <Menu.Item
                            key="/messages"
                            icon={
                                <img
                                    src={msgs}
                                    alt="messages"
                                    style={{ width: '20px' }}
                                />
                            }
                        >
                            {strings.sidebar.messages}
                            <Link to="/messages" />
                        </Menu.Item>

                        {/* offers */}
                        <Menu.Item
                            key="/offers"
                            icon={
                                <img
                                    src={offers}
                                    alt="offers"
                                    style={{ width: '20px' }}
                                />
                            }
                        >
                            {strings.sidebar.offers}
                            <Link to="/offers" />
                        </Menu.Item>

                        {/* advertisment */}
                        <Menu.Item
                            key="/advertisments"
                            icon={
                                <img
                                    src={advertisment}
                                    alt="advertisment"
                                    style={{ width: '20px' }}
                                />
                            }
                        >
                            {strings.sidebar.advertisments}
                            <Link to="/advertisments" />
                        </Menu.Item>
                        {/* categories & services */}
                        <Menu.Item
                            key="/categories-advantages"
                            icon={
                                <img
                                    src={add}
                                    alt="/categories-advantages"
                                    style={{ width: '20px' }}
                                />
                            }
                        >
                            {strings.sidebar.categories_services}
                            <Link to="/categories-advantages" />
                        </Menu.Item>

                        {/* logout */}
                        <Menu.Item
                            key="10"
                            icon={
                                <img
                                    src={logout}
                                    alt="logout"
                                    style={{ width: '20px' }}
                                />
                            }
                            onClick={showModal}
                        >
                            {strings.sidebar.logout}
                        </Menu.Item>
                    </Menu>
                </Sider>

                <Layout className="site-layout" style={{ background: '#fff' }}>
                    <Header
                        className="site-layout-background"
                        style={{ padding: 0, background: '#fff' }}
                    >
                        <div className="container-fluid">
                            <div className="row">
                                {/* burger menu appears only on md screens */}
                                <div className="d-lg-none col-4 d-flex justify-content-start align-items-center">
                                    {React.createElement(MenuOutlined, {
                                        className: 'trigger',
                                        onClick: mobileSidebarHandler,
                                    })}
                                </div>
                                {/* logo & logo out */}
                                <AppHeader />
                            </div>
                        </div>
                    </Header>
                    <Content
                        className="site-layout-background"
                        style={{ minHeight: 360, background: '#fff' }}
                    >
                        {/* ------------ login -------------- */}
                        <Route path="/login" component={Login} />
                        <Route
                            exact
                            path="/"
                            render={() => <Redirect to="/login" />}
                        />
                        {/*  Analytics  */}
                        {token ? (
                            <>
                                {/* dashboards */}
                                <Suspense fallback={<Loader />}>
                                    <Route
                                        path="/dashboards"
                                        component={Analytics}
                                    />
                                </Suspense>
                                {/* notifications */}
                                <Suspense fallback={<Loader />}>
                                    <Route
                                        path="/notifications"
                                        component={Notifications}
                                    />
                                </Suspense>

                                {/* register restaurant */}
                                <Suspense fallback={<Loader />}>
                                    <Route
                                        path="/register"
                                        component={Register}
                                    />
                                </Suspense>

                                {/* all restaurants */}
                                <Suspense fallback={<Loader />}>
                                    <Route path="/places" component={Places} />
                                </Suspense>

                                {/* all users */}
                                <Suspense fallback={<Loader />}>
                                    <Route path="/users" component={Users} />
                                </Suspense>

                                {/* reset password */}
                                <Suspense fallback={<Loader />}>
                                    <Route
                                        path="/reset-password/:id"
                                        component={ResetPassword}
                                    />
                                </Suspense>

                                {/* all reviews */}
                                <Suspense fallback={<Loader />}>
                                    <Route
                                        path="/all-reviews"
                                        component={AllReviews}
                                    />
                                </Suspense>

                                {/* place reviews */}
                                <Suspense fallback={<Loader />}>
                                    <Route
                                        path="/review/:id"
                                        component={PlaceReviews}
                                    />
                                </Suspense>

                                {/* messages */}
                                <Suspense fallback={<Loader />}>
                                    <Route
                                        path="/messages"
                                        component={Messages}
                                    />
                                </Suspense>

                                {/* offers */}
                                <Suspense fallback={<Loader />}>
                                    <Route
                                        path="/offers"
                                        component={Offers}
                                        exact
                                    />
                                </Suspense>

                                {/* add offer */}
                                <Suspense fallback={<Loader />}>
                                    <Route
                                        path="/add-offer"
                                        component={AddOffer}
                                        exact
                                    />
                                </Suspense>
                                {/* offer detail */}
                                <Suspense fallback={<Loader />}>
                                    <Route
                                        path="/offer/:id"
                                        component={OfferDetails}
                                        exact
                                    />
                                </Suspense>

                                {/* advertisment */}
                                <Suspense fallback={<Loader />}>
                                    <Route
                                        path="/advertisments"
                                        component={Advertisments}
                                        exact
                                    />
                                </Suspense>

                                {/* advertisment detail */}
                                <Suspense fallback={<Loader />}>
                                    <Route
                                        path="/advertisment/:id"
                                        component={AdvertismentsDetails}
                                        exact
                                    />
                                </Suspense>

                                {/* categories & services*/}
                                <Suspense fallback={<Loader />}>
                                    <Route
                                        path="/categories-advantages"
                                        component={CategoriesServices}
                                    />
                                </Suspense>

                                {/* ---------------------------- place form  ----------------- */}
                                {/*  add item */}
                                <Suspense fallback={<Loader />}>
                                    {details && (
                                        <Route
                                            path="/AddItem/:id"
                                            component={PlaceDataForm}
                                        />
                                    )}
                                </Suspense>
                            </>
                        ) : (
                            <Redirect to="/login" />
                        )}
                    </Content>
                </Layout>
            </Layout>
            <ToastContainer />
            {/* modal */}
                <ConfirmModal
                    visible={visible}
                    onCancel={handleCancel}
                    onAccept={logoutHandler}
                    msg={strings.sidebar.modal.msg}
                />
        </Router>
    );
};

export default RouterApp;
