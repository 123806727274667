import React from 'react';
//import strings from '../../../strings';
import { Form, Button, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
    },
};

const ConfirmModal = (props) => {
    const {visible, loading, msg, onCancel, onAccept } = props;
    return (
        <Modal centered visible={visible} onCancel={onCancel} footer={null}>
            <div className="content dashboard-forms">
                <p>{msg}</p> 
                <div className="ant-modal-footer confirm-footer">
                    <Form.Item>
                        <Button onClick={props.onCancel} className="secondary-btn">
                            لا
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" onClick={onAccept}>
                            {loading === true && <FontAwesomeIcon icon={faSpinner} className="ml-1 spinner" />}
                            نعم
                        </Button>
                    </Form.Item>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmModal;
