import * as axios from 'axios';
import { BASE_URL } from '../config';
import { successMessage, errorMessage } from '../utils';

axios.defaults.baseURL = BASE_URL;
// this will create common headers for all apis calls
axios.defaults.headers.common['Authorization'] =
    'bearer ' + localStorage.getItem('token');
export const Backend = {
    /* 
post requests => 
=================
*/
    postRequest(url, data) {
        return axios
            .post(url, data)
            .then(function (response) {
                console.log(response);
                return response;
            })
            .catch(function (err) {
                console.log(err.response);
                return err.response;
            });
    },

    /* get pages
     */
    getPages(url, page = 1) {
        return axios
            .get(`${url}`, { params: { page } })
            .then(function (response) {
                console.log(response);
                return response;
            })
            .catch(function (err) {
                console.log(err.response);
                return err.response;
            });
    },

    search(url) {
        return axios
            .get(`${url}`)
            .then(function (response) {
                console.log(response);
                return response;
            })
            .catch(function (err) {
                console.log(err.response);
                return err.response;
            });
    },

    // show place details
    showPlaceDetails(url) {
        return axios
            .get(`${url}`)
            .then(function (response) {
                console.log(response);
                return response;
            })
            .catch(function (err) {
                console.log(err.response);
                return err.response;
            });
    },

    /* get active & blocked reviews */
    getAllReviews(url, blocked, page = 1) {
        return axios
            .get(`${url}`, { params: { blocked, page } })
            .then(function (response) {
                console.log(response);
                return response;
            })
            .catch(function (err) {
                console.log(err.response);
                return err.response;
            });
    },

    /* get reviews of certain place */
    getReviews(url, placeId, pageNumber = 1) {
        return axios
            .get(`${url}?place_id=${placeId}&page=${pageNumber}`)
            .then(function (response) {
                console.log(response);
                return response;
            })
            .catch(function (err) {
                console.log(err.response);
                return err.response;
            });
    },

    // get messages
    getMessages(url, is_client, pageNumber = 1) {
        return axios
            .get(`${url}?is_client=${is_client}&page=${pageNumber}`)
            .then(function (response) {
                console.log(response);
                return response;
            })
            .catch(function (err) {
                console.log(err.response);
                return err.response;
            });
    },

    // categories
    getCategories(url, all, is_searchable) {
        return axios
            .get(`${url}`, { params: { all, is_searchable } })
            .then(function (response) {
                console.log(response);
                return response;
            })
            .catch(function (err) {
                console.log(err.response);
                return err.response;
            });
    },
    /*     
delete requests
==============
places
reviews
branches
working_hours
*/
    deleteRequest(url) {
        return axios
            .delete(url)
            .then(function (response) {
                console.log(response);
                return response;
            })
            .catch(function (err) {
                console.log(err.response);
                return err.response;
            });
    },

    // code for deleting images
    deleteImage(place_id, id) {
        console.log('images_ids', id);
        return axios
            .delete(`images/delete/${place_id}`, { data: { images_ids: [id] } })
            .then(function (response) {
                successMessage(response.data.message);
                console.log('deleteImage', response);
                return response.data;
            })
            .catch(function (err) {
                errorMessage(err.message);
                console.log('deleteImage error>>', err.response.data);
                const response = err.response ? err.response.data : undefined;
                //handleRequestError(response);
                return response;
            });
    },
};
