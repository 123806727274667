import { combineReducers } from 'redux';
import Auth from './Auth/Auth';
import PlacesReducer from './Plcaes';
import AddItem from './PlaceDataForm';
import PlaceDetails from './PlaceDetails/PlaceDetails';
import UsersReducer from './Users';
import Reviews from './Reviews';
import Messages from './Messages';
import Features from './Features';
import Statics from './Statics';
export default combineReducers({
    Auth,
    Statics,
    PlaceDetails,
    PlacesReducer,
    UsersReducer,
    Messages,
    Features,
    Reviews,
    AddItem,
});
