import * as ActionTypes from '../types';
import { Backend } from '../../services/Backend';
import { successMessage, errorMessage } from '../../utils';

// get users
export const getUsers = (pageNumber) => {
    return async (dispatch) => {
        const url = '/users';
        await Backend.getPages(url, pageNumber).then((response) => {
            console.log('users', response.data.data);
            dispatch({
                type: ActionTypes.GET_USERS,
                data: response.data.data,
            });
        });
    };
};
// search users
export const searchUsers = (phone) => {
    return async (dispatch) => {
        const url = `users?phone=${phone}`;
        await Backend.search(url).then((response) => {
            console.log('serach users', response.data.data);
            dispatch({
                type: ActionTypes.SEARCH_USERS,
                data: response.data.data,
            });
        });
    };
};

// reset password
export const resetPassword = (user_id, basicData) => {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.START_RESET_PASSWORD_LOADING,
        });
        const url = `admin/reset-pass/${user_id}`;
        await Backend.postRequest(url, basicData).then((response) => {
            if (response && response.data.code === 200) {
                dispatch({
                    type: ActionTypes.END_RESET_PASSWORD_LOADING,
                });
                dispatch({
                    type: ActionTypes.REST_PASSWORD,
                });
                successMessage(response.data.message);
            } else {
                dispatch({
                    type: ActionTypes.END_RESET_PASSWORD_LOADING,
                });
                errorMessage(response.data.message);
            }
        });
    };
};
