import * as ActionTypes from '../../actions/types';
import { updateObject } from '../utility';

const INIT_STATE = {
    loading: false,
    categories: null,
    subCategories: null,
    subCategoriesById: null,
    services: null,
};

/* for categories */
const getCategories = (state, action) => {
    return updateObject(state, {
        categories: action.categories.filter((item) => item.category_id === null),
        subCategories: action.categories.filter((item) => item.category_id !== null),
    });
};

/* for get subcategories by id (based on main category id) */
const getSubCategoriesById = (state, action) => {
    return updateObject(state, {
        subCategoriesById: action.subCategoriesById,
    });
};

/*for services */
const getServices = (state, action) => {
    return updateObject(state, {
        services: action.services,
    });
};

const AllReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        /* categories */
        case ActionTypes.START_STORE_CATEGORIES_LOADING:
            return { ...state, loading: true };
        case ActionTypes.END_STORE_CATEGORIES_LOADING:
            return { ...state, loading: false };

        case ActionTypes.START_UPDATE_CATEGORIES_LOADING:
            return { ...state, loading: true };
        case ActionTypes.END_UPDATE_CATEGORIES_LOADING:
            return { ...state, loading: false };

        case ActionTypes.START_DELETE_CATEGORIES_LOADING:
            return { ...state, loading: true };
        case ActionTypes.END_DELETE_CATEGORIES_LOADING:
            return { ...state, loading: false };

        case ActionTypes.GET_CATEGORIES:
            return getCategories(state, action);

        /* subcategories */
        case ActionTypes.GET_SUB_CATEGORIES_BY_ID:
            return getSubCategoriesById(state, action);

        /* services */
        case ActionTypes.START_STORE_SERVICES_LOADING:
            return { ...state, loading: true };
        case ActionTypes.END_STORE_SERVICES_LOADING:
            return { ...state, loading: false };

        case ActionTypes.START_UPDATE_SERVICES_LOADING:
            return { ...state, loading: true };
        case ActionTypes.END_UPDATE_SERVICES_LOADING:
            return { ...state, loading: false };

        case ActionTypes.START_DELETE_SERVICES_LOADING:
            return { ...state, loading: true };
        case ActionTypes.END_DELETE_SERVICES_LOADING:
            return { ...state, loading: false };

        case ActionTypes.GET_SERVICES:
            return getServices(state, action);

        default:
            return state;
    }
};
export default AllReducer;
