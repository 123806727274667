import * as ActionTypes from '../../actions/types';
import { updateObject } from '../utility';

const INIT_STATE = {
    statics: null
};

// get users
const getStatics = (state, action) => {
    return updateObject(state, {
        statics: action.data,
    });
};

const StaticsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_STATICS:
            return getStatics(state, action);
        default:
            return state;
    }
};
export default StaticsReducer;
